<template>
  <div class="g col-12 col-lg-6 c">
    <br>
    <div class="card">
        <div class="card-body">
            <div class="form-group">
              <h5>الرسالة</h5>
              <textarea class="form-control" v-model="message" @keyup="messageChanged()" @change="messageChanged()" rows="6" placeholder="اكتب الرسالة هنا..."></textarea>
            </div>
            <div class="col-12 text-center g">
                <button class="btn btn-primary" @click="send()">
                    <i class="fa fa-paper-plane"></i>
                    ارسال الآن
                </button>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-stipred table-sm">
                    <thead>
                        <th>
                            المعلم
                        </th>
                        <th>
                            الجوال
                        </th>
                        <th>
                            الرسالة
                        </th>
                    </thead>
                    <tbody id="students-messages">
                        <template v-for="(student, c) in students">
                        <tr :key="c">
                            <td>{{ student.name }}</td>
                            <td>{{ student.phone }}</td>
                            <td><textarea class='form-control msg' readonly v-model="student.message"></textarea></td>
                        </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <b-modal id="modal-students-ghiab-manual" size="lg" title="ارسال رسائل لاولياء الامور الغائبين" hide-footer>
        <div class="col-12 text-center g">
            <div class="alert alert-success text-center g" v-if="view_archive">
                <img src="/files/done.gif" style="border-radius: 50%">
                <br>
                <br>
                تم استلام الرسائل بنجاح وجاري ارسالها الآن٫<br>
                يمكنك متابعة الارسال <a :href="`/archive?school_id=${school_id}`" target="_blank"><strong><u>من هنا</u></strong></a>
            </div>
            <template v-if="!loading && !view_archive">
                <button class="btn btn-lg btn-primary" v-if="selected == 'sms' || selected == 'whatsapp-api'" @click="sendNow(selected)">
                    <i class="fa fa-check-square"></i>
                    ارسال الآن
                </button>
                <div class="col-12 text-center alert alert-success" v-if="selected == 'whatsapp'" id='remove-me-wa'>
                    <h4>
                        لتتمكن من ارسائل رسائل الواتسآب، يجب عليك تحميل أداة الخدمات الاضافية من سوق كروم.
                        <br><br>
                        <a href="https://bit.ly/3yAC50a" target="_blank" class="btn btn-success">
                            <i class="fa fa-download"></i>
                            إضغط هنا لتثبيت الاداة
                        </a>
                        <br>
                        وبعد تثبيتها قم بإعادة تحديث هذه الصفحة.
                    </h4>
                </div>
                <button class="btn btn-lg btn-primary" v-if="selected == 'whatsapp'" @click="sendNow(selected)" id="brmja-send">
                    <i class="fa fa-check-square"></i>
                    ارسال الآن
                </button>
            </template>
            <template v-if="loading && !view_archive">
                <button class="btn btn-lg btn-primary disabled">
                    جاري الإرسال...
                </button>
            </template>
        </div>
        <div class="col-12 table-responsive">
            <table class="table table-sm table-hover" id="brmja-messages-table">
                <thead>
                    <th>
                        الجوال
                    </th>
                    <th>
                        المعلم
                    </th>
                    <th>
                        الرسالة
                    </th>
                </thead>
                <tbody id="students-messages">
                    <template v-for="(student, c) in students">
                      <tr v-if="student.show" :key="c">
                          <td>{{ student.phone }}</td>
                          <td>{{ student.name }}</td>
                          <td><textarea class='form-control msg' readonly v-model="student.message"></textarea></td>
                      </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </b-modal>
    <button
      class="dnone"
      id="students-ghiab-manual"
      @click="sendModal()"
      v-b-modal.modal-students-ghiab-manual
    ></button>
  </div>
</template>

<script>
export default {
    data(){
        return {
            school_id: window.location.href.split("school_id=")[1].split("&")[0],
            message: "",
            students: [],
            loading: false,
            view_archive: false,
            classrooms: [],
            selected_classroom: "all",
            selected: ""
        }
    },
    created(){
      var g = this;
      var school_id = g.school_id
      setTimeout(() => {
        $("#date").html(new Date().toISOString().split('T')[0])
        $("body").append(`
          <textarea class='get-local-storage' name='teachers'></textarea>
        `)
        var i1 = setInterval(() => {
            if($(`.get-local-storage-value[name='teachers']`).length){
              clearInterval(i1)
              g.students = JSON.parse($(`.get-local-storage-value[name='teachers']`).val())
              var v = []
              g.students.forEach(element => {
                element.show = true
                element.message = ""
                v.push(element)
              });
              g.students = v
            }
        }, 10);
      }, 500);
    },
    methods: {
        refresh(){
          this.students = JSON.parse(JSON.stringify(this.students))
        },
        messageChanged(){
          var g = this;
          g.students = g.students.map(function(student){
            student.message = g.message.replace("{name}", student?.name).replace("{date}", new Date().toISOString().split("T")[0])
            return student;
          })
          g.refresh()
        },
        send(){
            $("#c-subscribe-ended").click()
            $("#after-send-settings-click").val("#students-ghiab-manual")
        },
        viewClass(){
          var g = this;
          var arr = []
          g.students.forEach(function(student){
            student.show = false
            if(g.selected_classroom == "all" || g.selected_classroom == student.classname + ' - ' + student.classroom){
              student.show = true
            }
            arr.push(student)
          })
          g.students = arr
        },
        sendModal(){
          var g =this;
          if(localStorage.getItem('send')){
              g.selected = JSON.parse(localStorage.getItem('send')).selected
          }
          g.messageChanged()
        },
        sendNow(method){
            var g = this;
            var c = []
            g.students.forEach(function(s){
              if(s.show){
                c.push({
                  phone: s.phone,
                  message: s.message
                })
              }
            })
            g.loading = true
            $.post(api + '/api/messages/send', {
                school_id: g.school_id,
                arr: JSON.stringify(c),
                method: method,
                settings: localStorage.getItem('send'),
                type: 'teachers'
            })
            .then(function(response){
                g.loading = false
                if(response.status == 100){
                    g.view_archive = true
                }else{
                    alert(response.response)
                }
            })
            .fail(function(){
                g.loading = false
                alert('حدث خطأ')
            })
        },
    }
}
</script>

<style>

</style>